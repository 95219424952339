export enum COMPONENT_TYPES {
  FORM_CONTAINER = 'wysiwyg.viewer.components.FormContainer',
  STATE_BOX = 'wysiwyg.viewer.components.StateBox',
  FORM_STATE = 'wysiwyg.viewer.components.StateBoxFormState',
  SITE_BUTTON = 'wysiwyg.viewer.components.SiteButton',
  LIGHTBOX_CONTAINER = 'wysiwyg.viewer.components.PopupContainer',
  TEXT_INPUT = 'wysiwyg.viewer.components.inputs.TextInput',
  TEXT_AREA = 'wysiwyg.viewer.components.inputs.TextArea',
  TEXT = 'wysiwyg.viewer.components.WRichText',
}
