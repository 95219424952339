import * as _ from 'lodash'
import { PROPERTIES, FormField } from '../../../constants/api-types'
import { getComponentTypeLayoutProp } from '../services/layout-service'
import { ResponsiveLayout, StackItemLayout, LayoutDataItem } from '@wix/platform-editor-sdk'
import { FIELDS_ROLES_TO_APPEAR_BEFORE_USER_NEW_FIELD } from '../../../constants/roles'
import { FieldPreset } from '../../../constants/field-types'

export type FieldLayoutProp = {
  [key: string]: PROPERTIES
} | null

export type FieldPropHandler = (fieldType: FieldPreset, value: PROPERTIES) => FieldLayoutProp | null
export type FieldDataHandler = FieldPropHandler

export const getTextAlignmentProp: FieldPropHandler = (fieldType, value) => {
  return getComponentTypeLayoutProp(fieldType, 'textAlignment', value)
}

export const getLabelMarginProp: FieldPropHandler = (fieldType, value) => {
  return getComponentTypeLayoutProp(fieldType, 'labelMargin', value)
}

export const getLabelPaddingProp: FieldPropHandler = (fieldType, value) => {
  return getComponentTypeLayoutProp(fieldType, 'labelPadding', value)
}

export const getInputTextPaddingProp: FieldPropHandler = (fieldType, value) => {
  return getComponentTypeLayoutProp(fieldType, 'textPadding', value)
}

export const findNewFieldStackOrder = (fieldsData: FormField[]): number => {
  const fieldsDefaultLayouts: LayoutDataItem[] = fieldsData
    .filter(fieldData => _.includes(FIELDS_ROLES_TO_APPEAR_BEFORE_USER_NEW_FIELD, fieldData.role))
    .map(fieldData => fieldData.layoutResponsive.itemLayouts[0])

  const lastFieldLayout: StackItemLayout = _.maxBy(
    fieldsDefaultLayouts as StackItemLayout[],
    'order'
  )

  return _.get(lastFieldLayout, 'order', 0) + 1
}

export const createStackItemLayout = (order: number): ResponsiveLayout => ({
  id: '',
  type: 'LayoutData',
  componentLayouts: [
    {
      type: 'ComponentLayout',
      width: {
        type: 'percentage',
        value: 90,
      },
      height: {
        type: 'auto',
      },
      hidden: false,
      breakpoint: undefined,
    },
  ],
  containerLayouts: [],
  itemLayouts: [
    {
      type: 'StackItemLayout',
      alignSelf: 'start',
      order,
      margins: {
        left: {
          type: 'px',
          value: 20,
        },
        top: {
          type: 'px',
          value: 20,
        },
      },
      justifySelf: 'end',
      breakpoint: undefined,
    },
  ],
  metaData: {
    isPreset: false,
    schemaVersion: '1.0',
    isHidden: false,
    pageId: 'c1dmp',
  },
})

export const calcUpdatesForStackFieldsByOrder = (
  responsiveLayouts: {
    componentRef: ComponentRef
    layoutResponsive: ResponsiveLayout
    role?: string
  }[],
  newFieldOrder: number
): { componentRef: ComponentRef; responsiveLayout: ResponsiveLayout }[] => {
  const fieldsNeededForUpdate = responsiveLayouts.filter(
    fieldData => getOrderFromResponsiveLayout(fieldData.layoutResponsive) >= newFieldOrder
  )
  return fieldsNeededForUpdate.map(fieldData => {
    const defaultStackItemLayout = <StackItemLayout>fieldData.layoutResponsive.itemLayouts[0]
    return {
      componentRef: fieldData.componentRef,
      responsiveLayout: {
        ...fieldData.layoutResponsive,
        itemLayouts: [{ ...defaultStackItemLayout, order: defaultStackItemLayout.order + 1 }],
      },
    }
  })
}

export const getOrderFromResponsiveLayout = (layoutResponsive: ResponsiveLayout): number =>
  (layoutResponsive.itemLayouts[0] as StackItemLayout).order
