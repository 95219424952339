import * as _ from 'lodash'
import { getPrimaryConnection, isInputField } from '../utils'
import { undoable, withBi } from '../decorators'
import CoreApi from '../core-api'
import { calcCommonStyleGlobalDesign, commonStyles } from '../services/form-style-service'
import { getTheme } from '../preset/themes-service'
import { innerText } from '../../../utils/utils'
import { EVENTS } from '../../../constants/bi'
import { roleDesignMapping } from '../manifests/global-design-manifest'
import { ROLE_FORM } from '../../../constants/roles'
import { COMPONENT_TYPES } from '../../../constants/component-types'
import { FieldPreset } from '../../../constants/field-types'
import { fieldsStore } from '../preset/fields/fields-store'
import { RavenStatic } from 'raven-js'
import { handleError } from '../../forms-editor-app/monitoring'

export default class StyleApi {
  private boundEditorSDK: BoundEditorSDK
  private coreApi: CoreApi
  private biLogger: any
  private experiments: any
  private ravenInstance: RavenStatic

  constructor(boundEditorSDK, coreApi: CoreApi, { biLogger, experiments, ravenInstance }) {
    this.boundEditorSDK = boundEditorSDK
    this.coreApi = coreApi
    this.biLogger = biLogger
    this.experiments = experiments
    this.ravenInstance = ravenInstance
  }

  public async getFieldsCommonStylesGlobalDesign(
    componentRef: ComponentRef
  ): Promise<commonStyles> {
    const compStyle = await this.boundEditorSDK.components.style.get({ componentRef })
    const formStyle = compStyle || { style: { properties: {} } }

    const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
    const childrenRefs = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })
    const fieldsStyleAndConnections = await this.boundEditorSDK.components.get({
      componentRefs: childrenRefs,
      properties: ['style', 'connections'],
    })

    const fields = _.flatMap(fieldsStyleAndConnections, ({ connections, style }) => {
      const primaryConnection = getPrimaryConnection(connections)
      const fieldRole = _.get(primaryConnection, 'role')
      const fieldType: FieldPreset = _.get(primaryConnection, 'config.fieldType')
      const componentType = _.get(fieldsStore.allFieldsData[fieldType], 'properties.componentType')

      return isInputField(fieldRole) && fieldType
        ? {
            style: _.get(style, 'style.properties'),
            designMapping:
              _.get(fieldsStore.allFieldsData[fieldType].designMapping, componentType) || [],
          }
        : []
    })

    return calcCommonStyleGlobalDesign([
      ...fields,
      {
        style: formStyle,
        designMapping: roleDesignMapping[ROLE_FORM][COMPONENT_TYPES.FORM_CONTAINER],
      },
    ])
  }

  private _keepTextAlignmentIfExists(currentTextStyle: string, newStyle: string): string {
    try {
      const currentTextFirstCloseTagIndex = currentTextStyle.indexOf('>')

      if (currentTextFirstCloseTagIndex === -1) {
        return newStyle
      }

      const currentTextFirstTagContent = currentTextStyle.slice(
        0,
        currentTextFirstCloseTagIndex + 1
      )

      const textAlignMatches = /text-align:\s*(\w+)/.exec(currentTextFirstTagContent)
      const alignment = _.get(textAlignMatches, '[1]')

      if (alignment) {
        const newStyleFirstCloseTagIndex = newStyle.indexOf('>')
        let newStyleFirstTagContent

        newStyleFirstTagContent = newStyle.slice(0, newStyleFirstCloseTagIndex + 1) // fetch first tag content
        newStyleFirstTagContent = _.replace(newStyleFirstTagContent, /text-align:\s*\w+[;]*/, '') // remove text alignment if exists

        if (newStyleFirstTagContent.indexOf('style') !== -1) {
          newStyleFirstTagContent = _.replace(
            newStyleFirstTagContent,
            'style="',
            `style="text-align: ${alignment};`
          ) // inject alignment within style attribute
        } else {
          newStyleFirstTagContent = _.replace(
            newStyleFirstTagContent,
            '>',
            ` style="text-align: ${alignment};">`
          ) // inject new style attribute with alignment
        }

        return newStyleFirstTagContent + newStyle.slice(newStyleFirstCloseTagIndex + 1) // return combined new style with changes
      }

      return newStyle
    } catch (err) {
      handleError(err, { extra: { message: 'Failed to execute _keepTextAlignmentIfExists' } })
      return newStyle
    }
  }

  private async _updateThemeStyle(componentRef: ComponentRef, style) {
    if (!style) {
      return
    }

    if (!_.isString(style)) {
      return this.boundEditorSDK.components.style.update({ componentRef, style })
    }

    const { text } = <any>await this.boundEditorSDK.components.data.get({ componentRef })
    const updatedStyle = this._keepTextAlignmentIfExists(text, style)
    const updatedStyleWithText = _.replace(updatedStyle, 'TITLE', innerText(text))

    return this.boundEditorSDK.components.data.update({
      componentRef,
      data: { text: updatedStyleWithText },
    })
  }

  private _updateThemeProps(componentRef: ComponentRef, props) {
    if (!props) {
      return
    }
    return this.boundEditorSDK.components.properties.update({
      componentRef,
      props,
    })
  }

  private _updateThemeData(componentRef: ComponentRef, data) {
    if (!data) {
      return
    }
    return this.boundEditorSDK.components.data.update({
      componentRef,
      data,
    })
  }

  public async getTheme(componentRef: ComponentRef) {
    const formConnection = await this.coreApi.getComponentConnection(componentRef)
    return _.get(formConnection, 'config.theme')
  }

  private async _updateTheme(componentRef: ComponentRef, theme: string) {
    if (await this.coreApi.isAppWidget(componentRef)) {
      componentRef = await this.coreApi.getFormContainerOfAppWidget(componentRef)
    }

    const { config } = await this.coreApi.getComponentConnection(componentRef)
    const themeFromConfig = _.get(config, 'theme')

    if (theme === themeFromConfig) {
      return
    }

    await this.coreApi.setComponentConnection(componentRef, { theme })
    const { controllerRef } = await this.coreApi.getComponentConnection(componentRef)
    const children = await this.boundEditorSDK.controllers.listConnectedComponents({
      controllerRef,
    })

    const { stylesByRole, propsByRole, dataByRole } = await getTheme(theme)

    if (_.isEmpty(stylesByRole)) {
      await this.coreApi.logFetchThemesFailed(componentRef, `theme ${theme} not found in resources`)
    }

    return Promise.all(
      _.map(children, async (childRef: ComponentRef) => {
        const { role } = await this.coreApi.getComponentConnection(childRef)

        return Promise.all([
          this._updateThemeProps(childRef, propsByRole[role]),
          this._updateThemeData(childRef, dataByRole[role]),
          this._updateThemeStyle(childRef, stylesByRole[role]),
        ])
      })
    )
  }

  @undoable()
  @withBi({ startEvid: EVENTS.PANELS.formStylePanel.CUSTOM_DESIGN_ACTION })
  public async updateTheme(componentRef: ComponentRef, theme: string, _biData = {}) {
    return this._updateTheme(componentRef, theme)
  }

  public async updateFieldPresetTheme(fieldPreset, theme: string) {
    if (!theme) return fieldPreset

    const role = _.get(fieldPreset, 'role')
    const { stylesByRole, propsByRole, dataByRole } = await getTheme(theme)

    const roleStyles = stylesByRole[role]
    const roleProps = propsByRole[role]
    let roleData = dataByRole[role]
    let style = !_.isString(roleStyles)
      ? this.coreApi.isResponsive()
        ? {
            stylesInBreakpoints: [
              {
                style: {
                  properties: roleStyles ? roleStyles : {},
                },
              },
            ],
          }
        : {
            style: {
              properties: roleStyles ? roleStyles : {},
            },
          }
      : {}

    if (_.isString(roleStyles)) {
      const text = _.get(fieldPreset, 'data.data.text', '')
      const newText = _.replace(roleStyles, 'TITLE', innerText(text))

      roleData = roleData
        ? {
            ...roleData,
            text: newText,
          }
        : { text: newText }
    }

    const styledFieldPreset = _.merge({}, fieldPreset, {
      data: {
        props: roleProps,
        data: roleData,
        style,
      },
    })

    return styledFieldPreset
  }

  public async updateThemeADI(componentRef: ComponentRef, theme: string) {
    return this._updateTheme(componentRef, theme)
  }
}
