import * as _ from 'lodash'
import { COMPONENT_TYPES } from '../../../constants/component-types'
import { RawComponentStructure } from '../../../constants/api-types'
import { innerText } from '../../../utils/utils'

type SkinTranslator = {
  defaultValues: ContactFormStyleDefaultValues
  translator: () => {
    [key in COMPONENT_TYPES]?: ContactFormStyleTranslator
  }
}

type ContactFormStyleDefaultValues = {
  properties: StyleTranslator
  propertiesSource: StyleTranslator
}
type ContactFormStyleTranslator = StyleTranslator | TextTranslator

type StyleTranslator = { [key in string]: string }
type TextTranslator = (
  contactFormStyle: ComponentStyle,
  contactFormStyleDefaultValues: ContactFormStyleDefaultValues,
  text: string
) => string

export const skinToStyleMapping: { [key in string]: SkinTranslator } = {
  'contactform.FullWidthButtonSkin': {
    defaultValues: {
      properties: {
        bg1: 'color_11',
        bg2: 'color_18',
        brd: 'color_18',
        brw: '1',
        fnt: 'font_7',
        fnt2: 'font_7',
        maxbrw: '1',
        shd: '0 0px 0px rgba(0, 0, 0, 0)',
        txt1: 'color_15',
        txt2: 'color_15',
        txt3: 'color_15',
        txtError: '#8b0000',
        txtSuccess: '#bada55',
      },
      propertiesSource: {
        bg1: 'theme',
        bg2: 'theme',
        brd: 'theme',
        brw: 'value',
        fnt: 'theme',
        fnt2: 'theme',
        maxbrw: 'value',
        shd: 'value',
        txt1: 'theme',
        txt2: 'theme',
        txt3: 'theme',
        txtError: 'value',
        txtSuccess: 'value',
      },
    },
    translator: () => {
      const textInputTranslator = {
        shd: 'shd',
        'boxShadowToggleOn-shd': 'boxShadowToggleOn-shd',
        fnt: 'fnt',
        fntlbl: 'fnt',
        bg: 'bg1',
        'alpha-bg': 'alpha-bg1',
        brw: 'brw',
        brwh: 'brw',
        brwf: 'brw',
        brwd: 'brw',
        brwe: 'brw',
        brd: 'brd',
        'alpha-brd': 'alpha-brd',
        brdh: 'brd',
        'alpha-brdh': 'alpha-brd',
        brdf: 'brd',
        'alpha-brdf': 'alpha-brd',
        brdd: 'brd',
        'alpha-brdd': 'alpha-brd',
        txt: 'txt1',
        brde: 'txtError',
        bge: 'bg1',
        'alpha-bge': 'alpha-bg1',
        txt2: 'txt3',
        bgh: 'bg1',
        'alpha-bgh': 'alpha-bg1',
        bgf: 'bg1',
        'alpha-bgf': 'alpha-bg1',
        txtlbl: 'txt3',
        txtlblrq: 'txt3',
      }
      return {
        [COMPONENT_TYPES.TEXT_INPUT]: textInputTranslator,
        [COMPONENT_TYPES.TEXT_AREA]: textInputTranslator,
        [COMPONENT_TYPES.SITE_BUTTON]: {
          bg: 'bg2',
          'alpha-bg': 'alpha-bg2',
          bgh: 'bg2',
          'alpha-bgh': 'alpha-bg2',
          txt: 'txt2',
          txth: 'txt2',
          fnt: 'fnt2',
        },
        [COMPONENT_TYPES.TEXT]: (
          contactFormStyle: ComponentStyle,
          contactFormStyleDefaultValues: ContactFormStyleDefaultValues,
          text: string
        ) => {
          const { properties, propertiesSource } = getKeyValue(
            contactFormStyle,
            contactFormStyleDefaultValues,
            'txtSuccess'
          )
          return `<p class="font_8" style="font-size: 14px;"><span style="font-family:helvetica-w01-roman,helvetica-w02-roman,helvetica-lt-w10-roman,sans-serif;">${createColorSpan(
            propertiesSource,
            properties
          )}<span style="font-size:14px;">${text}</span></span></span></p>`
        },
      }
    },
  },
}

const createColorSpan = (propertiesSource: string, properties: string) =>
  `<span ${propertiesSource === 'theme' ? 'class' : 'color'}="${properties}">`

const getKeyValue = (
  style: ComponentStyle,
  defaultValues: ContactFormStyleDefaultValues,
  key: string
) => ({
  properties: _.get(style, ['style', 'properties', key], defaultValues.properties[key]),
  propertiesSource: _.get(
    style,
    ['style', 'propertiesSource', key],
    defaultValues.propertiesSource[key]
  ),
})

export const translateContactFormStyle = (
  contactFormStyleTranslator: ContactFormStyleTranslator,
  contactFormStyleDefaultValues: ContactFormStyleDefaultValues,
  contactFormStyle: ComponentStyle,
  componentStructure: RawComponentStructure
): RawComponentStructure => {
  if (componentStructure.componentType === COMPONENT_TYPES.TEXT) {
    return {
      ...componentStructure,
      data: {
        ...componentStructure.data,
        text: (contactFormStyleTranslator as TextTranslator)(
          contactFormStyle,
          contactFormStyleDefaultValues,
          innerText(componentStructure.data.text)
        ),
      },
    }
  } else {
    const updatedStyle: ComponentStyle = _.reduce(
      contactFormStyleTranslator,
      (prevStyle, contactFormStyleKey, wixFormsStyleKey) => {
        const { properties, propertiesSource } = getKeyValue(
          contactFormStyle,
          contactFormStyleDefaultValues,
          contactFormStyleKey
        )
        prevStyle.style.properties[wixFormsStyleKey] = properties
        prevStyle.style.propertiesSource[wixFormsStyleKey] = propertiesSource
        return prevStyle
      },
      _.merge(
        {},
        {
          style: {
            propertiesSource: {},
          },
        },
        <ComponentStyle>componentStructure.style
      )
    )
    return { ...componentStructure, style: updatedStyle }
  }
}
