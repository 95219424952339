import * as _ from 'lodash'
import { RawComponentStructure, DynamicContactForm } from '../../../constants/api-types'
import { COMPONENT_TYPES } from '../../../constants/component-types'
import { ROLE_FORM, ROLE_MESSAGE } from '../../../constants/roles'
import { FormPreset } from '../../../constants/form-types'
import { SuccessActionTypes } from '../../../constants/success-settings'
import { FormPlugin } from '../../../constants/plugins'
import { FormsFieldPreset } from '../../../constants/field-types'
import * as successMessageStructure from '../../../assets/presets/hidden-message.json'
import * as submitButtonStructure from '../../../assets/presets/submit-button.json'
import { skinToStyleMapping, translateContactFormStyle } from './contact-form-style'

type ContactFormEnhancer = (
  contactForm: DynamicContactForm,
  convertedForm: RawComponentStructure
) => RawComponentStructure

export const convertContactFormToWixForms = (
  contactForm: DynamicContactForm
): RawComponentStructure => {
  const submitButtonComponent: RawComponentStructure = _.merge({}, submitButtonStructure, {
    layout: {
      width: 142,
      height: 40,
      x: 60,
      y: 52,
    },
    data: {
      label: contactForm.data.submitButtonLabel || 'Submit',
    },
  })
  const successMessageComponent: RawComponentStructure = _.merge({}, successMessageStructure, {
    layout: {
      width: contactForm.layout.width - 60,
      height: 21,
      x: 60,
      y: 102,
    },
    data: {
      text: `<p class="font_8"><span style="color:#8FCA8F;">${contactForm.data.successMessage ||
        'Thank you! Your form has been submitted.'}</span></p>`,
    },
  })
  const components: RawComponentStructure[] = [
    {
      type: 'Component',
      skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      layout: {
        width: 240,
        height: 42,
        x: 60,
        y: 0,
      },
      componentType: COMPONENT_TYPES.TEXT_INPUT,
      style: {
        type: 'TopLevelStyle',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        style: {
          properties: {
            'alpha-bg': '1',
            'alpha-bgd': '1',
            'alpha-bge': '1',
            'alpha-bgf': '1',
            'alpha-bgh': '1',
            'alpha-brd': '0.55',
            'alpha-brde': '0.6',
            'alpha-brdf': '1',
            'alpha-brdh': '0.7',
            bg: 'color_11',
            bgd: '#FFFFFF',
            bge: 'color_11',
            bgf: 'color_11',
            bgh: 'color_11',
            'boxShadowToggleOn-shd': 'false',
            brd: 'color_15',
            brdd: '#DBDBDB',
            brde: '#FF4040',
            brdf: 'color_18',
            brdh: 'color_15',
            brw: '1',
            fnt: 'font_8',
            rd: '0px',
            shd: '0px 1px 4px 0px rgba(0,0,0,0.6)',
            txt: 'color_15',
            txt2: 'color_14',
            txtd: '#DBDBDB',
          },
        },
        componentClassName: 'wysiwyg.viewer.components.inputs.TextInput',
        pageId: '',
        compId: '',
        styleType: 'custom',
        skin: 'wysiwyg.viewer.skins.appinputs.AppsTextInputSkin',
      },
      role: 'field_role_text',
      config: {
        crmLabel: 'First Name',
        fieldType: FormsFieldPreset.FIRST_NAME,
        crmType: 'firstName',
        crmTag: 'main',
      },
      props: {
        type: 'TextInputProperties',
        metaData: {
          schemaVersion: '1.0',
          autoGenerated: false,
        },
        required: false,
        textAlignment: 'left',
        textPadding: 12,
        placeholder: 'First Name',
        defaultTextType: 'placeholderText',
      },
      data: {
        type: 'TextInput',
        metaData: {
          isPreset: false,
          schemaVersion: '1.0',
          isHidden: false,
        },
        value: '',
        textType: 'text',
        maxLength: 100,
        placeholder: 'First Name',
      },
    },
    submitButtonComponent,
    successMessageComponent,
  ]
  const baseStructure = {
    type: 'Container',
    components: components,
    skin: 'wysiwyg.viewer.skins.FormContainerSkin',
    layout: {
      x: contactForm.layout.x - contactForm.layout.width - 50,
      y: contactForm.layout.y,
      width: contactForm.layout.width,
      height: contactForm.layout.height,
    },
    componentType: COMPONENT_TYPES.FORM_CONTAINER,
    style: {
      type: 'ComponentStyle',
      metaData: {
        isPreset: false,
        schemaVersion: '1.0',
        isHidden: false,
      },
      style: {
        properties: {
          bg: '#FFFFFF',
          'alpha-bg': 0,
        },
        propertiesSource: {
          bg: 'value',
        },
      },
      styleType: 'custom',
      skin: 'wysiwyg.viewer.skins.FormContainerSkin',
    },
    role: ROLE_FORM,
    config: {
      preset: FormPreset.CONTACT_FORM,
      labels: ['contacts-contacted_me'],
      emailId: '',
      errorMessage: 'Fill in missing fields.',
      secondsToResetForm: 3,
      formName: contactForm.data.formName || 'Contact',
      plugins: [
        {
          id: FormPlugin.FORM_BUILDER,
        },
      ],
      emailIds: [contactForm.data.toEmailAddress, contactForm.data.bccEmailAddress].filter(
        email => email
      ),
    },
    behaviors: contactForm.behaviors,
  }
  const enhancers: ContactFormEnhancer[] = [
    handleAfterSubmitBehavior,
    hideFormInMobile,
    enhanceWithStyle,
  ]
  const convertedStructure = enhancers.reduce<RawComponentStructure>(
    (previousStructure, enhancer) => enhancer(contactForm, previousStructure),
    baseStructure
  )
  return convertedStructure
}

const hideFormInMobile: ContactFormEnhancer = (
  contactForm: DynamicContactForm,
  structure: RawComponentStructure
): RawComponentStructure => {
  if (!_.get(contactForm.mobileHints, 'hidden')) {
    return structure
  }
  const hideFormInMobileAction: (
    structure: RawComponentStructure
  ) => RawComponentStructure = structure => {
    const structureWithMobileHidden: RawComponentStructure = {
      ...structure,
      mobileHints: { hidden: true, type: 'MobileHints' },
    }
    if (structure.components) {
      return {
        ...structureWithMobileHidden,
        components: structureWithMobileHidden.components.map(component =>
          hideFormInMobileAction(component)
        ),
      }
    } else {
      return structureWithMobileHidden
    }
  }
  return hideFormInMobileAction(structure)
}

const handleAfterSubmitBehavior: ContactFormEnhancer = (
  contactForm: DynamicContactForm,
  structure: RawComponentStructure
): RawComponentStructure => {
  const shouldShowLink = contactForm.data.onSubmitBehavior === 'link'
  const enhancedStructure = _.merge({}, structure, {
    config: {
      successActionType: shouldShowLink ? SuccessActionTypes.LINK : SuccessActionTypes.SHOW_MESSAGE,
      successLinkValue: shouldShowLink ? contactForm.data.link : undefined,
    },
  })
  if (shouldShowLink) {
    enhancedStructure.components = structure.components.filter(
      component => component.role !== ROLE_MESSAGE
    )
  }
  return enhancedStructure
}

const enhanceWithStyle: ContactFormEnhancer = (
  contactForm: DynamicContactForm,
  structure: RawComponentStructure
): RawComponentStructure => {
  const skinTranslator = skinToStyleMapping[contactForm.skin]
  const enhanceWithStyleAction: (
    structure: RawComponentStructure
  ) => RawComponentStructure = structure => {
    const componentTranslatorCreator = _.get(skinTranslator, 'translator')
    const componentTranslator = componentTranslatorCreator
      ? componentTranslatorCreator()[structure.componentType]
      : null
    const componentWithStyle: RawComponentStructure = componentTranslator
      ? translateContactFormStyle(
          componentTranslator,
          skinTranslator.defaultValues,
          contactForm.style,
          structure
        )
      : structure
    if (structure.components) {
      return {
        ...componentWithStyle,
        components: componentWithStyle.components.map(component =>
          enhanceWithStyleAction(component)
        ),
      }
    } else {
      return componentWithStyle
    }
  }
  return enhanceWithStyleAction(structure)
}
