import { FORMS_APP_DEF_ID } from '../../../constants'
import { ControllerType } from '../../../constants/api-types'

export const APP_WIDGET_DEFINITION = {
  componentType: 'platform.components.AppWidget',
  style: 'appWidget1',
  data: {
    type: 'AppController',
    name: 'wix-forms-controller',
    applicationId: FORMS_APP_DEF_ID,
    controllerType: ControllerType.WIX_FORMS,
  },
}
